<template>
  <CardForm :titulo="titleCard">
    <DataList ref="datatable" url="institucion" :fields="fields" class="mb-3" :toCuil="toCuil" :rowClick="opciones" />
  </CardForm>
</template>
<script>
import CardForm from '@/components/layouts/CardForm';
import DataList from '@/components/layouts/DataList';
import Swal from 'sweetalert2';
import axios from 'axios'
export default {
  name: "ListarInstituciones",
  components: {
    CardForm,
    DataList
  },
  props: {
      iconoTitulo: String,
      titleCard: String,
      toCreateAction: String,
      toCreateText: String,
    },
  data() {
    return {
      fields: [
        {
          name: "razon_social",
          label: "",
          type: "title",
          class: "text-primary",
        },
        {
          name: "cuit",
          label: "",
          type: "title",
          class: "text-primary",
          prepend: '-'
        },
        {
          name: "domicilio",
          label: "Domicilio",
          type: "field",
          class: "text-secondary",
        },
        {
          name: "localidad",
          label: "Localidad",
          type: "field",
          class: "text-secondary",
        },
        {
          name: "provincia",
          label: "Provincia",
          type: "field",
          class: "text-secondary",
        },
      ],
      toCuil: ['cuit']
    };
  },
  methods: {
    opciones(institucion) {
      this.$router.push({name:"VerInstitucion", params:{id:institucion.id}})
      // Swal.fire({
      //   title: institucion.razon_social,
      //   showCancelButton: true,
      //   confirmButtonText: "Editar",
      //   cancelButtonText: "Eliminar",
      //   confirmButtonColor: "#f7b924",
      //   cancelButtonColor: "#d33",
      //   reverseButtons: true,
      // }).then((result) => {
      //   if (result.value) {
          
      //   this.$router.push({name:"EditarInstitucion", params:{id:institucion.id}})
      //   } else if (
      //     /* Read more about handling dismissals below */
      //     result.dismiss === Swal.DismissReason.cancel
      //   ) {
      //     this.eliminarInstitucion(institucion);
      //   }
      // });
    },
    eliminarInstitucion(institucion) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: `¿Deseas eliminar ${institucion.razon_social} de manera permanente?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          axios
            .delete("institucion/" + institucion.id)
            .then((res) => {
              Swal.fire(
                "Eliminado",
                `${res.data.institucion.razon_social} se ha eliminado con éxito.`,
                "success"
              );
              console.log(this.$refs);
              this.$refs.datatable.update();
            })
            .catch((err) => console.log(err))
            .finally(() => (this.loading = false));
        }
      });
    },
  },
};
</script>
